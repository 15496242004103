import React, { Fragment } from 'react';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import RenderRootComponent from 'components/render-utils/render-root-component';
import { exchange_credentials as exchangeCredentials } from 'app/django-adapter';
import { store } from 'app/redux/store';
import Customer from 'app/api/customer';
import Modal from 'components/modal';
import Button from 'components/button';
import Alert from 'components/alerts/alert';
import ErrorBoundary from 'components/error/error-boundary';


export class AppAccountModalCheckEmail extends React.Component {
  static propTypes = {
    customer: PropTypes.shape({
      isInitialState: PropTypes.bool.isRequired,
      hasVerifiedEmail: PropTypes.bool.isRequired,
      summary: PropTypes.bool.isRequired,
    }).isRequired,
  }

  state = {
    manualClose: false,
    emailSent: true,
    isSending: false,
    errorMessage: '',
  }

  componentDidMount() {
    this.setState({
      emailSent: !!localStorage.getItem('checkEmailSent'),
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customer.isInitialState !== this.props.customer.isInitialState
      && !this.props.customer.hasVerifiedEmail
      && !this.props.customer.summary
      && !this.state.emailSent) {
      this.resendEmail();
    }
  }

  resendEmail = async () => {
    this.setState({ isSending: true, errorMessage: '' });

    try {
      await Customer.sendCheckEmail();

      this.setState({
        emailSent: true,
      });
      localStorage.setItem('checkEmailSent', 'true');
    } catch (error) {
      this.setState({
        errorMessage: error.message,
      });
    }

    this.setState({ isSending: false });
  }

  onClose = () => {
    this.setState({ manualClose: true });
  }

  // if summary is true, then we don't show the modal
  showModal() {
    const {
      props: { customer: { hasVerifiedEmail, summary, isInitialState } },
      state: { manualClose },
    } = this;

    return !hasVerifiedEmail && !summary && !manualClose && !isInitialState;
  }

  render() {
    const {
      errorMessage, isSending,
    } = this.state;

    return (
      <Modal className="modal-check-email" title="Email Verification" isShow={this.showModal()} onClose={this.onClose}>
        {!!errorMessage.length && (
          <Alert>{errorMessage}</Alert>
        )}
        {!isSending ? (
          <Fragment>
            <div className="alert alert-info">
              <p>
                We sent you an e-mail to verify your e-mail address.
                Please click on the link in the e-mail to complete your account setup process.
              </p>
            </div>
            <p className="text-right">
              <Button onClick={this.resendEmail} disabled={isSending}>Send the email again</Button>
            </p>
          </Fragment>
        ) : (
          <p className="text-center">Send email... <i className="fa fa-spin fa-spinner" /></p>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ customer }) => ({ customer });

export const ConnectedApp = connect(
  mapStateToProps,
)(AppAccountModalCheckEmail);

/* istanbul ignore next */
export const Root = () => (
  <Provider store={store}>
    <ErrorBoundary>
      <ConnectedApp />
    </ErrorBoundary>
  </Provider>
);

/* istanbul ignore next */
function render() {
  RenderRootComponent(hot(module)(Root), 'account-modal-check-email');
}

exchangeCredentials(store)
  .then(render)
  .catch(console.warn);
